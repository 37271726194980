<template>
  <Administracao />
</template>

<script>
import Administracao from "@/components/gestao/Administracao.vue";

export default {
  components: {
    Administracao
  }
};
</script>
